import { defineStore } from "pinia";

export const useAuthStore = defineStore("auth", {
  state: () => ({
    token: null,
    refreshToken: null,
    email: null,
    isAuthenticated: false,
    loginTriggered: false,
  }),
  actions: {
    setToken(token, refreshToken) {
      this.token = token;
      this.refreshToken = refreshToken;
      this.isAuthenticated = "true";
      localStorage.setItem("kc_token", token);
      localStorage.setItem("kc_refreshToken", refreshToken);
      localStorage.setItem("isAuthenticated", "true");
    },
    clearToken() {
      this.token = null;
      this.refreshToken = null;
      localStorage.removeItem("isAuthenticated");
      localStorage.removeItem("kc_token");
      localStorage.removeItem("kc_refreshToken");
      localStorage.removeItem("redirectAfterLogin");
    },
    setEmail(email) {
      this.email = email;
    },
    loadAuthenticationStatus() {
      const storedStatus = localStorage.getItem("isAuthenticated") === "true";
      this.isAuthenticated = storedStatus;
      this.token = localStorage.getItem("kc_token");
      this.refreshToken = localStorage.getItem("kc_refreshToken");
      this.loginTriggered = localStorage.getItem("loginTriggered") === "true";
    },
    setLoginTriggered(value) {
      this.loginTriggered = value;
      localStorage.setItem("loginTriggered", value);
    },
  },
  getters: {
    getAuthStatus: (state) => state.isAuthenticated,
    getToken: (state) => state.token,
    getRefreshToken: (state) => state.refreshToken,
    getEmail: (state) => state.email,
    getLoginTriggered: (state) => state.loginTriggered,
  },
});
