import axios from "axios";
import { useAuthStore } from "@/stores/useAuthStore";

const api = axios.create({
  baseURL: "https://beta-api.melioration24.com",
});

// Setze den Authorization-Header für jede Anfrage
api.interceptors.request.use((config) => {
  const authStore = useAuthStore();
  if (authStore.getAuthStatus) {
    config.headers.Authorization = `Bearer ${authStore.getToken}`;
  }
  return config;
});

export default api;
