import { defineStore } from "pinia";
export const useAuthUserStore = defineStore("authUser", {
  state: () => ({
    mail: null,
    first_name: null,
    last_name: null,
    language: localStorage.getItem("language"),
  }),
  actions: {
    setUser(mail, first_name, last_name) {
      this.mail = mail;
      this.first_name = first_name;
      this.last_name = last_name;
    },
    setLanguage(language) {
      this.language = language;
      localStorage.setItem("language", language); // Sprache im localStorage speichern
    },
  },
  getters: {
    getUser: (state) => {
      return {
        mail: state.mail,
        first_name: state.first_name,
        last_name: state.last_name,
      };
    },
    getLanguage: (state) => {
      return state.language;
    },
  },
});
