import { defineStore } from "pinia";

export const usePreloaderStore = defineStore("preloader", {
  state: () => ({
    loading: true,
  }),
  actions: {
    setLoading(value) {
      this.loading = value;
    },
  },
});
