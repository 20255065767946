import api from "@/helpers/db_api";
import { useAuthStore } from "@/stores/useAuthStore";

export async function fetchUserByEmail() {
  const authStore = useAuthStore();
  const email = authStore.getEmail;

  try {
    const response = await api.get("/benutzer", {
      params: { mail: `eq.${email}` },
    });
    return response.data.length > 0 ? response.data[0] : null;
  } catch (error) {
    console.error("Fehler beim Abrufen der Benutzerdaten:", error);
    throw error;
  }
}
