import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";

import "aos/dist/aos.css";
import i18n from "./i18n";
import store from "./state/store";

import VueSweetalert2 from "vue-sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";

import BootstrapVueNext from "bootstrap-vue-next";
import vClickOutside from "click-outside-vue3";
import VueApexCharts from "vue3-apexcharts";
import { vMaska } from "maska";

import VueFeather from "vue-feather";

import "@/assets/scss/config/material/app.scss";
import "@/assets/scss/mermaid.min.css";
import "bootstrap/dist/js/bootstrap.bundle";

import { createPinia } from "pinia";
const pinia = createPinia();

import keycloak from "@/helpers/auth";

import { useAuthStore } from "@/stores/useAuthStore";
import { fetchUserByEmail } from "@/helpers/userService";
import { useAuthUserStore } from "@/stores/useAuthUserStore";

import { theme } from "@/layouts/theme.js";

const app = createApp(App);
app.use(store);
app.use(pinia);
app.provide("$keycloak", keycloak);
app.use(VueApexCharts);
app.use(BootstrapVueNext);
app.component(VueFeather.type, VueFeather);
app.directive("maska", vMaska);
app.use(i18n);
app.use(VueSweetalert2);
app.use(vClickOutside);
app.config.globalProperties.$theme = theme;

const authStore = useAuthStore();
authStore.loadAuthenticationStatus();

import { usePreloaderStore } from "@/stores/usePreloaderStore";
const preloaderStore = usePreloaderStore();
preloaderStore.setLoading(true);

const currentPath = window.location.pathname;
if (currentPath !== "/") {
  localStorage.setItem("redirectAfterLogin", currentPath);
}

const redirectAfterLogin = localStorage.getItem("redirectAfterLogin");

// Initialize Keycloak once when the app starts
keycloak
  .init({
    onLoad: "check-sso",
    token: authStore.getToken, // Verwende gespeicherten Token, wenn verfügbar
    refreshToken: authStore.getRefreshToken,
    redirectUri: process.env.VUE_APP_URL,
    checkLoginIframe: false,
  })
  .then((authenticated) => {
    const authStore = useAuthStore();

    if (authenticated) {
      // Benutzer ist authentifiziert, Status speichern
      authStore.setToken(keycloak.token, keycloak.refreshToken);

      // Laden Sie das Benutzerprofil
      keycloak.loadUserProfile().then((profile) => {
        authStore.setEmail(profile.email);
        try {
          fetchUserByEmail().then((user) => {
            if (user) {
              const authUserStore = useAuthUserStore();
              authUserStore.setUser(user.mail, user.first_name, user.last_name);

              if (redirectAfterLogin) {
                localStorage.removeItem("redirectAfterLogin");
                //preloaderStore.setLoading(false);
                router.push(redirectAfterLogin).then(() => {
                  preloaderStore.setLoading(false); // Preloader ausblenden, nachdem die neue Seite geladen ist
                });
              } else {
                //router.push(window.location.pathname !== "/" ? window.location.pathname : "/");
                if (authStore.getLoginTriggered) {
                  authStore.setLoginTriggered(false);
                  //preloaderStore.setLoading(false);
                  router.push("/main").then(() => {
                    preloaderStore.setLoading(false); // Preloader ausblenden, nachdem die neue Seite geladen ist
                  });
                } else {
                  preloaderStore.setLoading(false);
                }
              }

              //router.push("/main");
            }
          });
        } catch (error) {
          console.error("Fehler beim Abrufen der Benutzerdaten:", error);
        }
      });

      // Rufe Benutzerdaten ab und speichere sie im AuthUserStore
    } else {
      // Keycloak not authenticated

      if (redirectAfterLogin) {
        keycloak.login();
      } else {
        router.push("/").then(() => {
          preloaderStore.setLoading(false); // Preloader ausblenden, nachdem die neue Seite geladen ist
        });
      }

      setTimeout(() => {
        window.history.replaceState(null, "", window.location.pathname);
      }, 200);
    }

    app.use(router).mount("#app");
  });
