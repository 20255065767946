<template>
  <div v-if="loading" class="custom-preloader">
    <div class="custom-spinner"></div>
  </div>
</template>

<script>
import { usePreloaderStore } from "@/stores/usePreloaderStore";
import { computed } from "vue";

export default {
  name: "CustomSpinner",
  setup() {
    const preloaderStore = usePreloaderStore();
    const loading = computed(() => preloaderStore.loading);

    return {
      loading,
    };
  },
};
</script>
