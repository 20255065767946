<template>
  <CustomSpinner />
  <router-view></router-view>
</template>

<script>
import CustomSpinner from "@/components/modules/CustomSpinner.vue";

export default {
  name: "App",
  components: {
    CustomSpinner,
  },
};
</script>
